/* .stepper::before {
	display: none;
} */
.stepper > div::after {
	position: absolute;
	display: block;
	content: '';
	width: 150%;
	top: 4.7rem;
	border-top: 0.125rem solid #b3b3b3;
	transform: rotate(90deg);
}
.stepper > div:last-child::after {
	display: none !important;
}

.stepper > div {
	margin-top: 2.5rem;
}

.stepper > div:first-child {
	margin-top: 0rem;
}

.stepper > div > p {
	margin-top: -0.25rem;
	max-width: none !important;
	margin-bottom: 1.75rem;
}
