@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
	/* Creighton-Pro-Black */
	@font-face {
		font-family: 'Creighton Pro';
		src: url('/fonts/creightonproblack-webfont-webfont.eot');
		src: url('/fonts/creightonproblack-webfont-webfont.svg') format('svg'),
			url('/fonts/creightonproblack-webfont-webfont.ttf') format('truetype'),
			url('/fonts/creightonproblack-webfont-webfont.woff') format('woff');
		font-style: normal;
		font-weight: 900;
		font-display: swap;
	}

	/* Creighton-Pro-Bold */
	@font-face {
		font-family: 'Creighton Pro';
		src: url('/fonts/creightonpro-bold/CreightonPro-Bold.eot');
		src: url('/fonts/creightonpro-bold/CreightonPro-Bold.eot?#iefix')
				format('embedded-opentype'),
			url('/fonts/creightonpro-bold/CreightonPro-Bold.woff2') format('woff2'),
			url('/fonts/creightonpro-bold/CreightonPro-Bold.woff') format('woff'),
			url('/fonts/creightonpro-bold/CreightonPro-Bold.ttf') format('truetype'),
			url('/fonts/creightonpro-bold/CreightonPro-Bold.svg#CreightonPro-Bold')
				format('svg');
		font-weight: 700;
		font-style: normal;
		font-display: swap;
	}

	/* Creighton-Pro-ExtraBoldItalic */
	@font-face {
		font-family: 'Creighton Pro';
		src: url('/fonts/creightonprobolditalic-webfont-webfont.eot');
		src: url('/fonts/creightonprobolditalic-webfont-webfont.svg') format('svg'),
			url('/fonts/creightonprobolditalic-webfont-webfont.ttf')
				format('truetype'),
			url('/fonts/creightonprobolditalic-webfont-webfont.woff') format('woff');
		font-weight: 800;
		font-style: italic;
		font-display: swap;
	}

	@font-face {
		font-family: 'Caveat';
		src: url('/fonts/Caveat-Bold.ttf') format('truetype');
		font-weight: 400;
		font-style: normal;
		font-display: block;
	}

	body {
		@apply font-standard text-base text-greyDarker;
	}
	sup {
		vertical-align: super;
	}
}

@layer components {
	.stepperwrapper::before {
		position: absolute;
		display: block;
		content: '';
		width: 100%;
		top: 1.25rem;
		border-top: 0.125rem solid #707070;
	}

	.search-overlay .page-overlay,
	.search-overlay .page-header-overlay,
	.menu-overlay .page-overlay,
	.faq-search-overlay .page-overlay,
	.faq-search-overlay .page-header-overlay {
		background: theme('backgroundColor.blackOpacity20');
		position: fixed;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		animation: fadeIn 0.3s ease-out forwards;
	}

	.search-overlay .page-overlay,
	.search-overlay .page-header-overlay,
	.menu-overlay .page-overlay {
		z-index: theme('zIndex.pageHeaderPageOverlay');
	}
	.search-overlay .page-header-overlay {
		position: absolute;
		z-index: 1;
	}
	.menu-overlay .page-header-part {
		z-index: theme('zIndex.pageHeaderPart');
	}

	.faq-search-overlay .page-overlay {
		z-index: theme('zIndex.faqSearchOverlay');
	}
	.faq-search-overlay .page-header-overlay {
		position: absolute;
		z-index: 100;
	}
}

@layer utilities {
	.scrolling-touch {
		-webkit-overflow-scrolling: touch;
	}
	.scrolling-auto {
		-webkit-overflow-scrolling: auto;
	}
	.focus-outline {
		@apply outline outline-2 outline-offset-2 outline-current;
	}
}

/* Rules outside Tailwind's layer optimization
---------------------------------------------------------------------------- */
/* Any custom CSS added to a layer above will only be included in the
final build if that CSS is actually used in code. The below rules should
always be included. */

#__next,
.global__page-wrapper {
	min-height: 100vh;
}

@media print {
	#onetrust-consent-sdk { display: none !important; }
}

/* The default user agent style for hidden has such a low specificity that any
display value will override it. Add !important to make it work more like intended. */
[hidden] {
	display: none !important;
}
